<template>
  <g>
    <svg:style>
      .cls-6 {
        fill: url(#Bottom_Gradient_2);
      }

      .cls-7 {
        fill: url(#LR_Gradient_2);
      }

      .cls-8 {
        fill: url(#LR_Gradient_2-2);
      }

      .cls-9 {
        fill: url(#Top_Gradient_2);
      }
    </svg:style>
    <linearGradient
      id="Bottom_Gradient_2"
      data-name="Bottom Gradient 2"
      :x1="doorLeftWidth1 + 2364.17"
      :y1="doorTopHeight + 13781.81"
      :x2="doorLeftWidth1 + 2364.17"
      :y2="doorTopHeight + 13773.28"
      gradientTransform="translate(2434.01 14046.77) rotate(180)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.05"/>
      <stop offset="0.05" stop-color="#010204" stop-opacity="0.5"/>
      <stop offset="0.06" stop-color="#010204" stop-opacity="0.5"/>
      <stop offset="0.58" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.87" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.89" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.6"/>
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      :x1="doorLeftWidth1 + 7924.4"
      :y1="doorTopHeight + 6302.24"
      :x2="doorLeftWidth1 + 7924.4"
      :y2="doorTopHeight + 6293.89"
      gradientTransform="translate(-6272.07 8071.48) rotate(-90)"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.3"/>
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3"/>
      <stop offset="0.02" stop-color="#ababac" stop-opacity="0.3"/>
      <stop offset="0.03" stop-color="#454647" stop-opacity="0.3"/>
      <stop offset="0.04" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="0.58" stop-color="#010204" stop-opacity="0.1"/>
      <stop offset="0.89" stop-color="#010204" stop-opacity="0.3"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.6"/>
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      :x1="doorLeftWidth1 + 109.48"
      :y1="doorTopHeight + 147.19"
      :x2="doorLeftWidth1 + 117.86"
      :y2="doorTopHeight + 147.19"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"/>
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      :x1="doorLeftWidth1 + 69.93"
      :y1="doorTopHeight + 29.23"
      :x2="doorLeftWidth1 + 69.93"
      :y2="doorTopHeight + 20.68"
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#fff" stop-opacity="0.6"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.56"/>
      <stop offset="0" stop-color="#fff" stop-opacity="0.44"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33"/>
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25"/>
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18"/>
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13"/>
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11"/>
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04"/>
      <stop offset="0.47" stop-color="#fff" stop-opacity="0"/>
      <stop offset="0.87" stop-color="#010204" stop-opacity="0.2"/>
      <stop offset="1" stop-color="#010204" stop-opacity="0.5"/>
    </linearGradient>
    <g id="C16">
      <g id="Panel">
        <polygon
          id="Shadow"
          class="cls-6"
          :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 109.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96}`"/>
        <polygon
          id="Panel_L"
          data-name="Panel L"
          class="cls-7"
          :points="`${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.23} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 20.68} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.23}`"/>
        <polygon
          id="Panel_R"
          data-name="Panel R"
          class="cls-8"
          :points="`${doorLeftWidth1 + 109.5} ${doorTopHeight1 + 29.43} ${doorLeftWidth1 + 109.48} ${doorTopHeight1 + 264.96} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 273.49} ${doorLeftWidth1 + 117.86} ${doorTopHeight1 + 20.88} ${doorLeftWidth1 + 109.5} ${doorTopHeight1 + 29.43}`"/>
        <polygon
          id="Highlight"
          class="cls-9"
          :points="`${doorLeftWidth1 + 109.7} ${doorTopHeight1 + 29.23} ${doorLeftWidth1 + 30.17} ${doorTopHeight1 + 29.23} ${doorLeftWidth1 + 21.82} ${doorTopHeight1 + 20.68} ${doorLeftWidth1 + 118.05} ${doorTopHeight1 + 20.68} ${doorLeftWidth1 + 109.7} ${doorTopHeight1 + 29.23}`"/>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 148
    },
  }
}
</script>
